import React from 'react';
import Layout from '../layouts/index';
import BookImage from '../images/book-sentencePatterns2.png';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { ReactPhotoCollage } from 'react-photo-collage';
import Recommended from '../components/Recommended';
import Link from 'gatsby-link';
import Payhip from '../components/Payhip';

class CourseDetails extends React.Component {
	render() {
		const setting = {
			width: '100%',
			height: ['500px', '170px'],
			layout: [1, 4],
			photos: [
				{
					source:
						'https://raw.githubusercontent.com/Caroline-Phung/HowToVietnameseAssets/master/Assets/patterns-book2-preview/book2-page1.png',
				},
				{
					source:
						'https://raw.githubusercontent.com/Caroline-Phung/HowToVietnameseAssets/master/Assets/patterns-book2-preview/book2-page2.png',
				},
				{
					source:
						'https://raw.githubusercontent.com/Caroline-Phung/HowToVietnameseAssets/master/Assets/patterns-book2-preview/book2-page3.png',
				},
				{
					source:
						'https://raw.githubusercontent.com/Caroline-Phung/HowToVietnameseAssets/master/Assets/patterns-book2-preview/book2-page4.png',
				},
				{
					source:
						'https://raw.githubusercontent.com/Caroline-Phung/HowToVietnameseAssets/master/Assets/patterns-book2-preview/book2-page5.png',
				},
			],
			showNumOfRemainingPhotos: true,
		};
		return (
			<Layout>
				{/* Course info */}
				<div className="container-fluid margin-from-header bg-white">
					<div className="container pt-4 pt-md-8 pb-8">
						<div className="row d-flex justify-content-md-center reverse-flex-mobile">
							<div className="col-sm-12 col-md-5 d-flex flex-column justify-content-left mr-md-6">
								<h2>Basic Grammar & Sentence Patterns - Book 2 (+Audio)</h2>
								<p>
									This series offer a practical and effective way of learning
									Vietnamese through essential grammar understanding and
									sentence patterns practicing.
								</p>
								<p>
									Level: <b>Beginner</b>
								</p>
								{/*<h1 className="text-success">$9.99</h1>*/}
								<div className="row pl-1 mt-2">
									<Payhip productID="X5ImL" label="Buy now - $12.99"></Payhip>
									{/* <OutboundLink
                    className="btn-simple btn-primary-outline"
                    // href="https://gum.co/emhpj?wanted=true"
                    href="https://payhip.com/b/X5ImL"
                  >
                    Get now for $12
                  </OutboundLink> */}
									<button class="btn-simple btn-primary-solid ml-2">
										<Link to="/vietnamese-ebook-bundle" target="_blank">
											View Bundle
										</Link>
									</button>
								</div>
							</div>
							<div className="col-7 col-md-3 mb-2 align-self-center">
								<img
									src={BookImage}
									className="img-fluid float-right rounded"
									alt="Vietnamese Basic Grammar and Sentence Patterns Book 2"
								></img>
							</div>
						</div>
					</div>
				</div>

				{/* Course contents */}
				<div className="container pt-md-10 pb-md-10 pt-6 pb-6">
					<div className="row justify-content-md-center">
						<div className="col-sm-12 col-md-8 ml-1 mr-1">
							<h2>What will you achieve?</h2>
							<ul>
								<li>
									Learn how to make Vietnamese sentences on your own through
									essential grammar understanding and sentence pattern
									practicing with <b>200 examples and practices</b>.
								</li>
								<li>
									Improve your listening with <b>30 minutes of Audio</b> MP3s at
									both slow and natural speeds.
								</li>
								<li>
									Practice along with a native speaker to improve your
									pronunciation.s
								</li>
								<li>
									Learn <b>300+ common Vietnamese words</b> in context
									throughout the book.
								</li>
							</ul>

							<h2 className="pt-8">Book Preview</h2>
							<ReactPhotoCollage {...setting} />
							<h2 className="pt-8">What's included?</h2>
							<ul>
								<li>PDF eBook version - 1 file</li>
								<li>EPUB eBook version - 1 file</li>
								<li>Audio MP3s - 1 zip file</li>
							</ul>
						</div>
					</div>
				</div>
				<Recommended
					recommended={[
						'/vietnamese-ebook-bundle',
						'/vietnamese-sentence-patterns-book-1',
					]}
				/>
			</Layout>
		);
	}
}

export default CourseDetails;
